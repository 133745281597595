
declare var YT: any;
declare var Cookiebot: any;

export default class Blocks {
    private window: any = window;
    public static readonly desktopBreakpoint = 992;
    private readonly needConsentClass = "need-cookie-consent";
    private videoBlocks: JQuery;

    public static videoIds: string[] = [];
    public static players: any[] = [];
   
    constructor() {
        this.videoBlocks = $('.video-block');

        this.updateEqualHeights();
        this.checkCookiebot();
        this.setListeners();
        this.fixExternalLinks();
    }

    private setListeners() {
        $(this.window).resize(() => {
            this.updateEqualHeights();
        });
    }

    private checkCookiebot() {
        if (!this.window.UsingCookieConsent) {
            this.initYouTube();
        }
        else if (Cookiebot
            && Cookiebot.consent
            && Cookiebot.consent.marketing) {
            this.initYouTube();
        }
        else {
            this.videoBlocks.addClass(this.needConsentClass);
            this.window.addEventListener('CookiebotOnAccept', e => {
                if (Cookiebot.consent.marketing) {
                    this.initYouTube();
                }
            }, false);
        }
    }

    private fixExternalLinks() {
        $('.block a').each((i, e) => {
            var appDomain = window.location.hostname || 'seikovision.com' || 'http://seikovision.azurewebsites.net/';
            var hostName = (e as any).hostname;
            var url = $(e).attr('href');

            if (appDomain !== hostName) {
                $(e).attr('target', '_blank');

            }
        });
    }

    private updateEqualHeights() {
        const winWidth = $(this.window).width();

        $('.equal-height').each(() => {
            const el: any = $('.equalize', $(this));

            if (winWidth > Blocks.desktopBreakpoint) {
                el.matchHeight(false);
            }
            else {
                el.matchHeight("remove");
            }
        });
    }

    private initYouTube() {

        this.videoBlocks.removeClass(this.needConsentClass);
        //youtube script
        var tag = document.createElement('script');
        tag.src = "//www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        this.videoBlocks.each((i, e) => {
            var videoBlock = $(e);
            var videoWrapper = $('.video-wrapper', videoBlock);
            var thumbnailContainer = $('.video-thumbnail-container', videoBlock);
            var videoId = videoWrapper.data('video-id');
            Blocks.videoIds.push(videoId);

            thumbnailContainer.on('click', () => {
                thumbnailContainer.hide();
                videoWrapper.show();
                (window as any).ytplayers[videoId].playVideo();
            });
        });
    }
}

$(function () {
    function onPlayerStateChange(event) {
        if (event.data == YT.PlayerState.ENDED) {
            var playerIframe = $('#' + $(event.target.h).attr('id'));
            var videoBlock = playerIframe.closest('.video-block');
            var thumbnailContainer = $('.video-thumbnail-container', videoBlock);
            thumbnailContainer.show();
        }
    };
    
    (window as any).onYouTubeIframeAPIReady = function () {
        (window as any).ytplayers = [];

        for (var i = 0; i < Blocks.videoIds.length; i++) {
            var videoId = Blocks.videoIds[i];
            //Blocks.players[videoId] = new YT.Player('player-' + videoId, {
            (window as any).ytplayers[videoId] = new YT.Player('player-' + videoId, {
                videoId: videoId,
                playerVars: {
                    'autoplay': 0,
                    'rel': 0,
                    'showinfo': 0
                },
                events: {
                    'onStateChange': onPlayerStateChange
                }
            });
        }
    };
 
    $(document).on('click', 'img[data-image]', function (event) {
        var target = $(event.currentTarget).data('target');
        var targetimg = $(target);
        var img = $(event.currentTarget).data('image');
        img = img.split('?')[0];
        $(event.currentTarget).closest('.product-thumbs').find('.selected').removeClass('selected');
        $(event.currentTarget).addClass('selected');
        targetimg.attr('src', img + "?w=798&h=300&mode=crop");
    });

    if ($('form.product-picker').length > 0) {
        var setProductImages = function (id, images) {
            var qsSmall = "?w=200&h=100&mode=crop"; 
            var qslarge = "?w=798&h=300&mode=crop"; 
            if (typeof (images[0]) != 'undefined') {
                $('#product-image-' + id).attr('src', images[0] + qslarge);
            }
                
            var thumbs = $('[data-target="#product-image-' + id + '"]');
            // add placeholder that is shown while the new image is loading
            thumbs.attr('src', "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
            thumbs.addClass("hidden");

            for (var i = 0; i < images.length; i++) {
                $(thumbs[i])
                    .attr('src', images[i] + qsSmall)
                    .attr('data-image', images[i])
                    .data('image', images[i])
                    .removeClass("hidden");
            }
        }
        $('form.product-picker').each(function () {

            $(this).find('[name="color"]').on('change', function (event) {
                $(this).parent().submit();
            });
            $(this).on('submit', function (event) {
                event.preventDefault();
                var form = $(this);
                $.ajax({
                    type: form.data("method"),
                    url: form.data("action"),
                    data: form.serialize()
                }).done(function (data) {
                    var images = data;//JSON.parse(data);
                    var id = form.data('id')
                    setProductImages(id, images);
                }).fail(function (data) {
                    // Error ?
                    console.log(data);
                });
            });
        });
    }
});