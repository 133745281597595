! function (e, t) {
    if ("function" == typeof define && define.amd) define(["exports", "module"], t);
    else if ("undefined" != typeof exports && "undefined" != typeof module) t(exports, module);
    else {
        var i = {
            exports: {}
        };
        t(i.exports, i), e.autosize = i.exports
    }
}(this, function (e, t) {
    "use strict";

    function i(e) {
        function t() {
            var t = window.getComputedStyle(e, null);
            "vertical" === t.resize ? e.style.resize = "none" : "both" === t.resize && (e.style.resize = "horizontal"), l = "content-box" === t.boxSizing ? -(parseFloat(t.paddingTop) + parseFloat(t.paddingBottom)) : parseFloat(t.borderTopWidth) + parseFloat(t.borderBottomWidth), n()
        }

        function i(t) {
            var i = e.style.width;
            e.style.width = "0px", e.offsetWidth, e.style.width = i, u = t, d && (e.style.overflowY = t), n()
        }

        function n() {
            var t = e.style.height,
                n = document.documentElement.scrollTop,
                s = document.body.scrollTop,
                a = e.style.height;
            e.style.height = "auto";
            var o = e.scrollHeight + l;
            if (0 === e.scrollHeight) return void (e.style.height = a);
            e.style.height = o + "px", document.documentElement.scrollTop = n, document.body.scrollTop = s;
            var r = window.getComputedStyle(e, null);
            if (r.height !== e.style.height) {
                if ("visible" !== u) return void i("visible")
            } else if ("hidden" !== u) return void i("hidden");
            if (t !== e.style.height) {
                var d = document.createEvent("Event");
                d.initEvent("autosize:resized", !0, !1), e.dispatchEvent(d)
            }
        }
        var s = void 0 === arguments[1] ? {} : arguments[1],
            a = s.setOverflowX,
            o = void 0 === a || a,
            r = s.setOverflowY,
            d = void 0 === r || r;
        if (e && e.nodeName && "TEXTAREA" === e.nodeName && !e.hasAttribute("data-autosize-on")) {
            var l = null,
                u = "hidden",
                f = function (t) {
                    window.removeEventListener("resize", n), e.removeEventListener("input", n), e.removeEventListener("keyup", n), e.removeAttribute("data-autosize-on"), e.removeEventListener("autosize:destroy", f), Object.keys(t).forEach(function (i) {
                        e.style[i] = t[i]
                    })
                }.bind(e, {
                    height: e.style.height,
                    resize: e.style.resize,
                    overflowY: e.style.overflowY,
                    overflowX: e.style.overflowX,
                    wordWrap: e.style.wordWrap
                });
            e.addEventListener("autosize:destroy", f), "onpropertychange" in e && "oninput" in e && e.addEventListener("keyup", n), window.addEventListener("resize", n), e.addEventListener("input", n), e.addEventListener("autosize:update", n), e.setAttribute("data-autosize-on", !0), d && (e.style.overflowY = "hidden"), o && (e.style.overflowX = "hidden", e.style.wordWrap = "break-word"), t()
        }
    }

    function n(e) {
        if (e && e.nodeName && "TEXTAREA" === e.nodeName) {
            var t = document.createEvent("Event");
            t.initEvent("autosize:destroy", !0, !1), e.dispatchEvent(t)
        }
    }

    function s(e) {
        if (e && e.nodeName && "TEXTAREA" === e.nodeName) {
            var t = document.createEvent("Event");
            t.initEvent("autosize:update", !0, !1), e.dispatchEvent(t)
        }
    }
    var a = null;
    "undefined" == typeof window || "function" != typeof window.getComputedStyle ? (a = function (e) {
        return e
    }, a.destroy = function (e) {
        return e
    }, a.update = function (e) {
        return e
    }) : (a = function (e, t) {
        return e && Array.prototype.forEach.call(e.length ? e : [e], function (e) {
            return i(e, t)
        }), e
    }, a.destroy = function (e) {
        return e && Array.prototype.forEach.call(e.length ? e : [e], n), e
    }, a.update = function (e) {
        return e && Array.prototype.forEach.call(e.length ? e : [e], s), e
    }), t.exports = a
});
window.ZO = window.ZO || {};

/*

 Zooma HubSpot Cookie v1.6217
 ----------------------------

 Dependencies:
 https://github.com/js-cookie/js-cookie

 How to use:
 var hsCookie = ZO.hsCookie.getInstance();

 */

if (!Cookies) {
    throw new Error("Javascript Cookie must be loaded!");
}

window.ZO = window.ZO || {};

ZO.hsCookie = (function () {

    "use strict";

    var instance; // prevent modification of "instance" variable

    function init() {

        /****************************************
         *
         *      PRIVATE METHODS & PROPERTIES
         *
         */

        var _domain = '';


        function _setDomain(domain) {
            _domain = domain;
        }

        function _getDomain() {
            return _domain;
        }

        /****************************************
         *
         *      PUBLIC METHODS & PROPERTIES
         *
         */

        return {

            init: function (domain) {
                if (domain == undefined) {
                    domain = window.location.hostname;
                    domain = domain.substr(domain.indexOf('.'), domain.length - domain.indexOf('.'));
                }
                _setDomain(domain);

            },

            initCookiebot: function () {
                this.init()

                if (window.Cookiebot
                    && window.Cookiebot.consent
                    && window.Cookiebot.consent.marketing) {


                    //
                    // Marketing cookies disabled by Cookiebot
                    //
                    if (window._hsq != null) {
                        window.console.log('Disabling HubSpot cookies');

                        window._hsq.push(['doNotTrack', { track: false }]);
                        //window._hsq.push(['revokeCookieConsent']);
                        Cookies.set('__hs_opt_out', 'yes', {
                            path: '/',
                            domain: _getDomain(),
                            expires: 156
                        });

                        setTimeout(() => {
                            this.clearMarketingCookies()
                        }, 1000)
                    }
                } else if (window.Cookiebot
                    && window.Cookiebot.consent
                    && window.Cookiebot.consent.marketing) {
                    //
                    // Marketing cookies enabled by Cookiebot
                    //
                    if (window._hsq != null) {
                        window._hsq.push(['doNotTrack', { track: true }]);
                        window._hsq.push(['revokeCookieConsent']);
                    }
                }
            },

            clearMarketingCookies: function () {
                const success = Cookies.remove('hubspotutk', { path: '/' });
                Cookies.remove('__hstc', { path: '/' });
                Cookies.remove('__hssc', { path: '/' });
                Cookies.remove('__hssrc', { path: '/' });
                Cookies.remove('messagesUtk', { path: '/' });

                window.console.log('Removed marketing cookies');
                return success
            },

            clearCookie: function () {
                var success = false;

                success = Cookies.remove('hubspotutk', { path: '/', domain: _getDomain() });
                Cookies.remove('__hstc', { path: '/', domain: _getDomain() });

                // Experimental
                Cookies.remove('__hssc', { path: '/', domain: _getDomain() });
                Cookies.remove('hsfirstvisit', { path: '/', domain: _getDomain() });
                Cookies.remove('hsPagesViewedThisSession', { path: '/', domain: _getDomain() });

                window.console.log('Removed cookies');

                return success;
            }

        };

    }

    return {

        // Get the Singleton instance if one exists
        // or create one if it doesn't
        getInstance: function () {

            if (!instance) {
                instance = init();
            }

            return instance;
        }

    };


})();
let debugLog;

if (ZO.helper = function () {
    "use strict";
    var e = 768,
        t = 500,
        i = {
            Android: function () {
                return navigator.userAgent.match(/Android/i)
            },
            BlackBerry: function () {
                return navigator.userAgent.match(/BlackBerry/i)
            },
            iOS: function () {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i)
            },
            Opera: function () {
                return navigator.userAgent.match(/Opera Mini/i)
            },
            Windows: function () {
                return navigator.userAgent.match(/IEMobile/i)
            },
            any: function () {
                return this.Android() || this.BlackBerry() || this.iOS() || this.Opera() || this.Windows()
            }
        },
        n = $(window),
        s = $("body"),
        a = {
            iosViewportFix: function () {
                navigator.userAgent.match(/iPhone OS 6|iPad OS 6|iPod OS 6|iPhone OS 5|iPad OS 5|iPod OS 5/i) && $('meta[name="viewport"]').each(function () {
                    $(this).attr("content", "width=device-width, initial-scale=1, maximum-scale=1")
                })
            },
            placeholderIsSupported: function () {
                var e = document.createElement("input");
                return "placeholder" in e
            },
            placeholderizer: function (e, t, i) {
                debugLog.add("Run placeholderizer"), t = t === !0, i = void 0 == i || i, e.find(".hs-form-field").each(function () {
                    var e = $(this).find('input[type="text"], input[type="email"], input[type="number"], input[type="password"], input[type="tel"], input[type="url"], textarea'),
                        n = $(this).find("label"),
                        s = $(this).find('input[type="radio"], input[type="checkbox"]').length > 0;
                    e.length && !e.attr("placeholder") && e.attr("placeholder", n.text()), e.length && !t && n.hide(), i && s && n.show()
                })
            },
            hideLabelsOnPlaceholder: function (e) {
                debugLog.add("Hide labels on inputs that have placeholders"), e.find(".hs-form-field, .field").each(function () {
                    var e = $(this).find('select, input[type="text"], input[type="email"], input[type="number"], input[type="password"], input[type="tel"], input[type="url"], textarea'),
                        t = $(this).find("label");
                    e.length && e.attr("placeholder") && t.addClass("sr-only").hide()
                })
            },
            clickEvent: function (e) {
                return e = e || "touchstart", this.mobilecheck() ? e : "click"
            },
            mobilecheck: function () {
                return i.any()
            },
            isMobileSize: function () {
                return !$("html").hasClass("mfb") || ($(window).width() < e || $(window).height() < t)
            },
            getBrowser: function () {
                if (void 0 !== navigator) {
                    var e = navigator.userAgent.search("Safari"),
                        t = navigator.userAgent.search("Chrome"),
                        i = navigator.userAgent.search("Firefox"),
                        n = navigator.userAgent.search("MSIE 8.0"),
                        s = navigator.userAgent.search("MSIE 9.0"),
                        a;
                    return e > -1 ? a = "Safari" : t > -1 ? a = "Chrome" : i > -1 ? a = "Firefox" : s > -1 ? a = "MSIE 9.0" : n > -1 && (a = "MSIE 8.0"), a
                }
                return ""
            },
            isMicrosoftUser: function () {
                return !!/MSIE 10/i.test(navigator.userAgent) || (!(!/MSIE 9/i.test(navigator.userAgent) && !/rv:11.0/i.test(navigator.userAgent)) || !!/Edge\/12./i.test(navigator.userAgent))
            },
            isMobileSafari: function () {
                var e = /(iPhone)/i.test(navigator.userAgent),
                    t = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
                return !(!e || !t)
            },
            isIE: function () {
                var e = window.navigator.userAgent,
                    t = e.indexOf("MSIE ");
                return !!(t > 0 || navigator.userAgent.match(/Trident.*rv\:11\./));
                return !1
            },
            getInternetExplorerVersion: function () {
                var e = -1,
                    t, i;
                return "Microsoft Internet Explorer" == navigator.appName ? (t = navigator.userAgent, i = new RegExp("MSIE ([0-9]{1,}[.0-9]{0,})"), null !== i.exec(t) && (e = parseFloat(RegExp.$1))) : "Netscape" == navigator.appName && (t = navigator.userAgent, i = new RegExp("Trident/.*rv:([0-9]{1,}[.0-9]{0,})"), null !== i.exec(t) && (e = parseFloat(RegExp.$1))), e
            },
            mutationObserverSupported: function () {
                return !this.isIE() || getInternetExplorerVersion() >= 11
            },
            scrollTo: function (e, t, i) {
                var s = {
                    animTime: 600,
                    easing: "easeInOutCubic",
                    callback: function () { }
                };
                if ($(e).length) {
                    i = void 0 != i && i, "object" == typeof i ? s = $.extend(s, i) : "function" == typeof i && (s.callback = i);
                    var a = 0,
                        o = void 0 !== t ? t : -130,
                        r = $(e).offset().top + o - a + 1,
                        d = $(document).height() - $(window).height();
                    r = 0 === r ? 1 : r, r > d && (r = d);
                    var l = Math.ceil(Math.abs(r - n.scrollTop())) / 4 + s.animTime;
                    this.scrollToPos(r, l, s.callback, s.easing)
                }
            },
            scrollToPos: function (e, t, i, n) {
                n = n || !1;
                var s = !1;
                if ((void 0 == n || void 0 != n && void 0 == jQuery.easing[n]) && (n = !1), e < 0 && (e = 0), e >= 0) return $("body").stop().data("scrolling", !0), void (n ? $("html, body").animate({
                    scrollTop: e
                }, t, n, function () {
                    s || ($("body").data("scrolling", !1), void 0 !== i && i(), s = !0)
                }) : $("html, body").animate({
                    scrollTop: e
                }, t, function () {
                    s || ($("body").data("scrolling", !1), void 0 !== i && i(), s = !0)
                }))
            },
            isScrollHalfScreen: function (e) {
                var t = $(window).scrollTop(),
                    i = $(e).offset().top,
                    s = i - t,
                    a = t > n.height() / 2;
                return a
            },
            prepPhoneNum: function (e) {
                return void 0 !== e ? e.replace(/[^+0-9]/g, "") : ""
            },
            stripQueryStringAndHashFromPath: function (e) {
                return e.split("?")[0].split("#")[0]
            },
            urlStripQueryHash: function (e) {
                return e.split("?")[0].split("#")[0]
            },
            getCurrentUrlStripQuery: function () {
                return this.urlStripQueryHash(document.URL)
            },
            getUrlHashVal: function (e) {
                if (void 0 != e && e.indexOf("#") !== -1) {
                    var t = e.substring(e.indexOf("#") + 1);
                    return t
                }
                return ""
            },
            getUrlParamByName: function (e) {
                for (var t = window.location.search.substring(1), i = t.split("&"), n = 0; n < i.length; n++) {
                    var s = i[n].split("=");
                    if (s[0] == e) return s[1]
                }
                return ""
            },
            trim: function (e) {
                return e.replace(/(?:(?:^|\n)\s+|\s+(?:$|\n))/g, "").replace(/\s+/g, " ")
            },
            throttle: function (e, t, i) {
                var t = t || (t = 250),
                    n, s;
                return function () {
                    var a = i || this,
                        o = (new Date).getTime(),
                        r = arguments;
                    n && o < n + t ? (clearTimeout(s), s = setTimeout(function () {
                        n = o, e.apply(a, r)
                    }, t)) : (n = o, e.apply(a, r))
                }
            },
            focusFirstInput: function (e) {
                this.mobilecheck() || e.find(".hs-input").each(function () {
                    if ($(this).is(":empty")) return $(this).focus(), !1
                })
            },
            getAnimEndEvent: function () {
                var e, t = document.createElement("fakeelement"),
                    i = {
                        transition: "transitionend",
                        OTransition: "oTransitionEnd",
                        MozTransition: "transitionend",
                        WebkitTransition: "webkitTransitionEnd"
                    };
                for (e in i)
                    if (void 0 !== t.style[e]) return i[e]
            }
        };
    return a
}(), debugLog = function () {
    "use strict";
    var e = {
        enabled: !1,
        sepChar: "-",
        sepLen: 90,
        sepStartLen: 5,
        msgSep: " ",
        setEnabled: function (e) {
            this.enabled = e
        },
        getEnabled: function () {
            return this.enabled
        },
        log: function (e) {
            window.console && window.console.log && window.console.log(e)
        },
        add: function () {
            if (this.enabled) {
                var e = "",
                    t = !1,
                    i;
                for (i in arguments) void 0 !== arguments[i] ? "object" == typeof arguments[i] || arguments[i].constructor == Array ? (t && (this.log(e), e = "", t = !1), this.log(arguments[i])) : (t && (e += this.msgSep), e += arguments[i], t = !0) : (t && (e += this.msgSep), e += "null", t = !0);
                t && this.log(e)
            }
        },
        newSection: function (e) {
            if (this.enabled)
                if (this.log(""), void 0 !== e) {
                    var t = this.sepLen - e.length - this.sepStartLen - 1,
                        i = t > 0 ? new Array(t).join(this.sepChar) : new Array(this.sepStartLen).join(this.sepChar);
                    this.log(new Array(this.sepStartLen).join(this.sepChar) + " " + e.toUpperCase() + " " + i)
                } else this.log(new Array(this.sepLen).join(this.sepChar))
        },
        endSection: function () {
            this.enabled && (this.log(new Array(this.sepLen).join(this.sepChar)), this.log(""))
        }
    };
    return window.pr || (window.pr = function (t) {
        e.add(t)
    }), window.getUrlParamByName || (window.getUrlParamByName = function (e) {
        for (var t = window.location.search.substring(1), i = t.split("&"), n = 0; n < i.length; n++) {
            var s = i[n].split("=");
            if (s[0] == e) return s[1]
        }
        return ""
    }), e
}(), !window.console) var console = {
    log: function () { },
    warn: function () { },
    error: function () { },
    time: function () { },
    timeEnd: function () { }
};
! function ($) {
    $(function () {
        1 != window.getUrlParamByName("debug") && 1 != window.getUrlParamByName("local") && window.location.hostname.indexOf(".local") === -1 || debugLog.setEnabled(!0)
    })
}(jQuery),
    function ($) {
        $.fn.hsFormSharingan = function (e) {
            var t = $.extend({}, $.fn.hsFormSharingan.defaults, e),
                n = ["hsForm_", "hs_form_target_", "hs_form_target_widget", "hs_form_target_module", "hs_form_target_wizard_module", "hs_cos_wrapper_blog_comments", "comment-form"];
            if (t.embedded) return this.each(function () {
                var e = $(this),
                    i = e.attr("id"),
                    n, s, a = function () {
                        s = Date.now(), n = setInterval(function () {
                            e.find(".hs-form").length ? (clearInterval(n), e.find(".hs-form").hsFormTsukuyomi(t)) : e.find(".hbspt-form").length && (clearInterval(n), e.find(".hbspt-form").hsFormTsukuyomi(t)), Date.now() - s >= t.timeOut && (clearInterval(n), e.remove(), debugLog.add("hsFormSharingan search for an embedded HubSpot timed out"))
                        }, t.speed)
                    };
                a()
            });
            var s = "";
            for (i in n) s += '[id^="' + n[i] + '"]', s += i < n.length - 1 ? ", " : "";
            if (void 0 == this.attr("id") || this.attr("id").indexOf("hs_form_target_widget") === -1 && this.attr("id").indexOf("hs_form_target_module") == -1) var a = this.find(s);
            else var a = this;
            return debugLog.add(1 == a.length ? "Found 1 form in content" : "Found " + a.length + " forms in content"), a.each(function () {
                if (void 0 === $.fn.hsFormTsukuyomi) throw new Error("hsFormTsukuyomi has not been loaded yet!");
                $(this).hsFormTsukuyomi(t)
            })
        }, $.fn.hsFormSharingan.defaults = {
            fields: {},
            embedded: !1,
            timeout: 5e3,
            callback: function () { },
            beforeCallback: function () { },
            speed: 50
        }
    }(jQuery),
    function ($) {
        Object.size || (Object.size = function (e) {
            var t = 0,
                i;
            for (i in e) e.hasOwnProperty(i) && t++;
            return t
        }), $.fn.hsFormTsukuyomi = function (e) {
            var t = $.extend({}, $.fn.hsFormSharingan.defaults, e),
                n = ["hsForm_", "hs_form_target_", "hs_form_target_widget", "hs_form_target_module", "hs_form_target_wizard_module", "hs_cos_wrapper_blog_comments", "comment-form"],
                s = !1,
                a;
            for (i in n) this.attr("id").indexOf([n[i]]) !== -1 && (s = !0);
            if (debugLog.add(this.attr("class")), !this.hasClass("hbspt-form") && !this.hasClass("hs-form") && !s) throw new Error("hsFormTsukuyomi can't be performed on a non-HubSpot element");
            return this.each(function () {
                var e = !1,
                    i = $(this),
                    n = i.attr("id"),
                    s, a, o = function () {
                        var n = Object.size(t.fields);
                        if (e && n > 0) {
                            var s = i.find(".hs_submit"),
                                a = "",
                                o = 0;
                            for (key in t.fields) {
                                var r = t.fields[key],
                                    d = i.find('.hs-input[name="' + key + '"]');
                                0 == d.length ? (a = '<input class="hs-input" type="hidden" name="' + key + '" value="' + r + '"/>', s.prepend(a), o++) : d.val(r).change()
                            }
                            o > 0 && debugLog.add("Created " + o + " new fields"), debugLog.add("Updated values on " + n + " fields")
                        }
                    },
                    r = function () {
                        a = Date.now(), s = setInterval(function () {
                            "" != i.html() && (e = !0, clearInterval(s), debugLog.newSection("#" + n), t.beforeCallback.call(i, i), o(), t.callback.call(i, i)), Date.now() - a >= t.timeOut && (clearInterval(s), debugLog.add("FGHubSpotForm timed out for #" + n))
                        }, t.speed)
                    };
                r()
            })
        }, $.fn.hsFormTsukuyomi.defaults = {
            fields: {},
            timeout: 5e3,
            callback: function () { },
            beforeCallback: function () { },
            speed: 50
        }
    }(jQuery),
    function ($) {
        $.fn.hsFloatedLabels = function (e) {
            var t = $.extend({}, $.fn.hsFloatedLabels.defaults, e);
            return this.find(".hs-form-field").each(function () {
                var e = $(this).find('input[type="text"], input[type="email"], input[type="number"], input[type="password"],  input[type="tel"], textarea'),
                    i = $(this).find("label"),
                    n = $(this).find(".Select"),
                    s = 0 == $(this).find('input[type="radio"], input[type="checkbox"], input[type="file"], select').length,
                    a = n.length;
                if (s && !e.hasClass(t.disabledClass) && 0 == $(this).find(".hs-floated-label-input").length) {
                    $(this).addClass(t.wrapClass).addClass(t.textInputClass), t.floatOnValue && $(this).addClass(t.floatOnValueClass).append('<span class="' + t.placeholderClass + '">' + $(this).find("> label").html() + "</span>");
                    var o = $(this);
                    if ("" !== e.val() && o.addClass(t.pinnedClass), 0 != $(this).find("legend").text().length && o.addClass(t.hasLegendClass), t.enablePlaceholder) {
                        var r = e.attr("placeholder") ? e.attr("placeholder") : "";
                        e.data("placeholder", r)
                    }
                    e.removeAttr("placeholder"), e.on("keydown", function (e) {
                        e.metaKey || e.shiftKey || e.ctrlKey || 8 == e.keyCode || 46 == e.keyCode || o.addClass(t.hasValueClass)
                    }).on("keyup", function () {
                        "" != $(this).val() ? o.addClass(t.hasValueClass) : o.removeClass(t.hasValueClass)
                    }).on("focus", function () {
                        o.addClass(t.focusedClass), t.enablePlaceholder && $(this).attr("placeholder", $(this).data("placeholder")), t.enablePlaceholder && t.floatOnValue && $(this).attr("placeholder") && o.addClass(t.hasValueClass)
                    }).on("blur", function () {
                        "" == e.val() ? (o.removeClass(t.pinnedClass).removeClass(t.hasValueClass), t.enablePlaceholder && $(this).attr("placeholder", "")) : (o.addClass(t.pinnedClass), e.trigger("change")), o.removeClass(t.focusedClass)
                    }), e.is("textarea") ? setTimeout(function () {
                        e.trigger("blur")
                    }, 500) : e.trigger("blur")
                } else if (a && !n.hasClass(t.disabledClass) && 0 == $(this).find(".hs-floated-label-input").length) {
                    $(this).append('<div class="' + t.wrapClass + " " + t.selectInputClass + '"></div>');
                    var d = $(this).find("." + t.wrapClass);
                    "" !== n.val() && d.addClass(t.pinnedClass), 0 != $(this).find("legend").text().length && d.addClass(t.hasLegendClass), i.appendTo(d), n = n.appendTo(d);
                    var l = $(this).find('option:first[value=""]').text();
                    $(this).find('option:first[value=""]').data("label", l).text(""), n.find("select").removeAttr("placeholder").on("focus", function () {
                        d.addClass(t.focusedClass);
                        var e = $(this).find('option:first[value=""]');
                        e.text(e.data("label"))
                    }).on("blur", function () {
                        if ("" == $(this).find("option:selected").val()) {
                            d.removeClass(t.pinnedClass);
                            var e = $(this).find('option:first[value=""]');
                            e.text("")
                        } else d.addClass(t.pinnedClass);
                        d.removeClass(t.focusedClass)
                    }), n.find("select").trigger("blur")
                }
                var u = $(this);
                setTimeout(function () {
                    u.addClass("hs-floated-label-input--initiated")
                }, 500)
            })
        }, $.fn.hsFloatedLabels.defaults = {
            floatOnValue: !1,
            wrapClass: "hs-floated-label-input",
            floatOnValueClass: "hs-floated-label-input--float-on-value",
            placeholderClass: "hs-floated-label-placeholder",
            disabledClass: "hs-floated-label-disabled",
            textInputClass: "hs-text-input",
            selectInputClass: "hs-select-input",
            hasValueClass: "has-value",
            focusedClass: "focused",
            pinnedClass: "pinned",
            enablePlaceholder: !1,
            hasLegendClass: "has-legend"
        }
    }(jQuery);
var Seiko = window.Seiko || {};
Seiko.portal = function ($) {
    "use strict";

    function e() {
      //  debugLog.add("Seiko.portal.init()"), t()
    }

    function t() {
        $(".hubspot-form-wrapper, .item__form").each(function (e) {
            var t = "hubspot-form-" + e;
            $(this).addClass(t), "" !== $(this).text() && $("." + t).hsFormSharingan({
                embedded: !0,
                callback: r
            })
        })
    }

    function i(e) {
        var t = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return t.test(e)
    }

    function n(e) {
        var t = function () {
            var t = e.find('select[name="' + l.fieldNames.subjectDropdown + '"]'),
                i = t.find('option[value="' + l.subjectChoices.askQuestion + '"]').is(":selected");
            return !i || i && "" !== e.find(l.questionFieldsetSelector).find(".hs-input").val()
        },
            n = function () {
                var t = e.find('select[name="' + l.fieldNames.subjectDropdown + '"]'),
                    i = t.find('option[value="' + l.subjectChoices.askQuestion + '"]').is(":selected");
                return !i || i && "" !== e.find(l.zipFieldsetSelector).find(".hs-input").val()
            },
            s = i(e.find('input[type="email"]').val());
        return s && t() && n()
    }

    function s(e) {
        var t, i = function (i, n) {
            n = void 0 != n && n;
            var a = e.find("." + d.fieldsetClassPrefix + parseInt(i.attr("data-fieldset-index"), 10)),
                o = !1,
                r = !1;
            if ("radio" == i.attr("type") && i.is(":checked") ? (o = !0, $('input[name="' + i.attr("name") + '"]').removeClass(d.fieldNoValueClass)) : "checkbox" == i.attr("type") && i.is(":checked") ? (o = !0, $('input[name="' + i.attr("name") + '"').removeClass(d.fieldNoValueClass)) : i.is("select") && i.val() ? o = !0 : (i.is("input") || i.is("textarea")) && i.val() && i.val().length >= d.formInputMinLength && (o = !0), a.length && a.hasClass(d.fieldsetHiddenClass) && (r = !0, o = !0), o) {
                i.removeClass(d.fieldNoValueClass);
                var l = e.find("." + d.fieldsetClassPrefix + (parseInt(i.attr("data-fieldset-index"), 10) + 1));
                if (l.length) {
                    l.addClass(d.fieldsetEnabledClass);
                    var u = l.find(".hs-input"),
                        f = u.is("input") || u.is("textarea"),
                        c = i.is("input") || i.is("textarea");
                    u.prop("disabled", !1, n), !n || !r && c || ZO.helper.mobilecheck() || !f || (clearTimeout(t), t = setTimeout(function () {
                        l.find(".hs-input:first").focus()
                    }, d.fieldAnimSpeed))
                }
            }
            s() && (e.find("fieldset").addClass(d.fieldsetEnabledClass), e.find(".hs-input").prop("disabled", !1))
        },
            n = function (e) {
                s() ? e.addClass(d.formValidatedClass).removeClass(d.formIncompleteClass).find('input[type="submit"]').prop("disabled", !1) : e.removeClass(d.formValidatedClass).addClass(d.formIncompleteClass).find('input[type="submit"]').prop("disabled", !1)
            },
            s = function () {
                var t = e.find('input[name="' + l.fieldNames.userType + '"]'),
                    i = e.find('select[name="' + l.fieldNames.subjectDropdown + '"]');
                return t.val() && i.val()
            },
            a = function () {
                var t = e.find('select[name="' + l.fieldNames.subjectDropdown + '"]'),
                    i = t.find('option[value="' + l.subjectChoices.askQuestion + '"]').is(":selected") || t.find('option[value="' + l.subjectChoices.leaveComment + '"]').is(":selected"),
                    n = e.find(l.questionFieldsetSelector).find(".hs-input");
                return !i || i && "" !== e.find(l.questionFieldsetSelector).find(".hs-input").val()
            },
            o = function () {
                var t = e.find('select[name="' + l.fieldNames.subjectDropdown + '"]'),
                    i = t.find('option[value="' + l.subjectChoices.findOptician + '"]').is(":selected") || t.find('option[value="' + l.subjectChoices.findOptician + '"]').is(":selected"),
                    n = e.find(l.zipFieldsetSelector).find(".hs-input");
                return !i || i && "" !== e.find(l.zipFieldsetSelector).find(".hs-input").val()
            };
        e.on("submit", function (e) {
            return !(!a() && !o()) || (e.stopPropagation(), e.preventDefault(), !1)
        }), e.find("fieldset").each(function (t) {
            var n = $(this).find(".hs-input"),
                s = n.length ? n.attr("name") : "";
            if ($(this).addClass(d.fieldsetClassPrefix + t), n.length && !n.is("hidden")) {
                n.addClass(d.fieldNoValueClass).prop("disabled", !0), n.attr("data-fieldset-index", t), $(this).addClass(d.fieldsetClassPrefix + s);
                var a = e.find('select[name="' + l.fieldNames.subjectDropdown + '"]');
                if ($(this).find('input[name="' + l.fieldNames.userType + '"]').length) {
                    var o = a.find('option[value="' + l.subjectChoices.bookMeeting + '"]'),
                        r = a.find('option[value="' + l.subjectChoices.beContacted + '"]'),
                        u = a.find('option[value="' + l.subjectChoices.findOptician + '"]');
                    $(this).find('input[name="' + l.fieldNames.userType + '"]').on("change", function () {
                        var e = !!a.length && a.find('option[value="' + l.subjectChoices.bookMeeting + '"]'),
                            t = !!a.length && a.find('option[value="' + l.subjectChoices.beContacted + '"]'),
                            i = !!a.length && a.find('option[value="' + l.subjectChoices.findOptician + '"]');
                        if (a)
                            if ($(this).val().toLowerCase() == l.personaChoices.specWearer.toLowerCase()) 0 == i.length && a.append(u), e.is(":selected") && (debugLog.add("Reset request subject dropdown!"), a.addClass(d.fieldNoValueClass).find("option:first").prop("selected", !0).trigger("change")), t.is(":selected") && (debugLog.add("Reset request subject dropdown!"), a.addClass(d.fieldNoValueClass).find("option:first").prop("selected", !0).trigger("change")), e.length && e.remove(), t.length && t.remove();
                            else if ($(this).val() == l.personaChoices.other) {
                                var n = a.find('option[value="' + l.subjectChoices.askQuestion + '"]');
                                e.is(":selected") && (debugLog.add("Reset request subject dropdown!"), a.addClass(d.fieldNoValueClass).find("option:first").prop("selected", !0).trigger("change")), t.is(":selected") && (debugLog.add("Reset request subject dropdown!"), a.addClass(d.fieldNoValueClass).find("option:first").prop("selected", !0).trigger("change")), e.length && e.remove(), t.length && t.remove(), i.is(":selected") && (debugLog.add("Reset request subject dropdown!"), a.addClass(d.fieldNoValueClass).find("option:first").prop("selected", !0).trigger("change")), i.length && i.remove()
                            } else $(this).val() == l.personaChoices.optician && (0 == t.length && a.append(r), 0 == e.length && a.append(o), i.is(":selected") && (debugLog.add("Reset request subject dropdown!"), a.addClass(d.fieldNoValueClass).find("option:first").prop("selected", !0).trigger("change")), i.length && i.remove())
                    })
                }
                if ($(this).find('select[name="' + l.fieldNames.subjectDropdown + '"]')) {
                    var f = e.find(l.questionFieldsetSelector),
                        c = e.find(l.zipFieldsetSelector),
                        h = "";
                    f.length && a.on("change", function () {
                        var e = a.find('option[value="' + l.subjectChoices.askQuestion + '"]').is(":selected") || a.find('option[value="' + l.subjectChoices.leaveComment + '"]').is(":selected");
                        if (e)
                            if (f.removeClass(d.fieldsetHiddenClass), f.find(".hs-input").prop("required", !0), f.find("> div").is(":hidden")) {
                                0 == f.find(".hs-form-required").length && (h = f.find("div > label").html() + '<span class="hs-form-required">*</span>', f.find("> div > label").html(h));
                                var t = $(this);
                                f.find("> div").slideDown(d.fieldAnimSpeed, function () {
                                    i(t, !0)
                                })
                            } else i($(this), !0);
                        else f.addClass(d.fieldsetHiddenClass), f.find(".hs-input").prop("required", !1), f.find("> div").is(":visible") ? f.find("> div").slideUp(d.fieldAnimSpeed, function () {
                            i(f.find(".hs-input"), !0)
                        }) : i(f.find(".hs-input"), !0)
                    }), c.length && a.on("change", function () {
                        var e = a.find('option[value="' + l.subjectChoices.findOptician + '"]').is(":selected") || a.find('option[value="' + l.subjectChoices.findOptician + '"]').is(":selected");
                        if (e)
                            if (c.removeClass(d.fieldsetHiddenClass), 0 == c.find(".hs-form-required").length && (h = c.find("div > label").html() + '<span class="hs-form-required">*</span>', c.find("> div > label").html(h)), c.find("> div").is(":hidden")) {
                                var t = $(this);
                                c.find("> div").slideDown(d.fieldAnimSpeed, function () {
                                    i(t, !0)
                                })
                            } else i($(this), !0);
                        else c.addClass(d.fieldsetHiddenClass), c.find(".hs-input").prop("required", !1), c.find("> div").is(":visible") ? c.find("> div").slideUp(d.fieldAnimSpeed, function () {
                            i(c.find(".hs-input"), !0)
                        }) : i(c.find(".hs-input"), !0)
                    })
                }
            }
        }), e.find(".hs-input").each(function () {
            $(this).on("change", function () {
                $(this).attr("name") == l.fieldNames.subjectDropdown ? i($(this)) : i($(this), !0), n(e)
            }), $(this).is("input") || $(this).is("textarea")
        }), e.addClass(d.formIncompleteClass).find('input[type="submit"]').prop("disabled", !0), e.find(l.questionFieldsetSelector).find("> div").hide(), e.find(l.zipFieldsetSelector).find("> div").hide(), $("." + d.fieldsetClassPrefix + "0").addClass(d.fieldsetEnabledClass).find(".hs-input").prop("disabled", !1)
    }

    function a(e, t) {
        if (typeof autosize !== 'undefined') {
            e && (o(e, t), ZO.helper.mobilecheck() || autosize(e.find("textarea")), $(document).trigger("content:changed"))
        }
    }

    function o(e, t) {
        var i = e || u;
        t = void 0 === t || t, i.find("select").each(function () {
            $(this).parent().hasClass("Select") || $(this).parent().addClass("Select"), "contact_request_subject" == $(this).attr("name") && $(this).parent().addClass("hs-floated-label-disabled")
        }), void 0 !== e && t && e.hsFloatedLabels({
            enablePlaceholder: !0
        })
    }

    function r(e) {
        e && (a(e), s(e))
    }
    var d = {
        fluidBreakpoint: 992,
        refDocOuterWidth: 1460,
        fixedHeaderOffset: 50,
        flexSectionInClass: "flex-section--in",
        topSectionID: "start",
        headerSelector: ".Header",
        headerFixedBodyClass: "fixed-header",
        fieldsetClassPrefix: "fieldset-",
        fieldsetEnabledClass: "fieldset-enabled",
        fieldsetHiddenClass: "fieldset-hidden",
        fieldAnimSpeed: 500,
        fieldNoValueClass: "no-value",
        formValidatedClass: "form-validated",
        formIncompleteClass: "form-incomplete",
        formInputMinLength: 3
    },
        l = {
            fieldNames: {
                userType: "user_type_selector",
                subjectDropdown: "contact_request_subject"
            },
            personaChoices: {
                optician: "Optician",
                specWearer: "Spectacle wearer",
                other: "Other"
            },
            subjectChoices: {
                askQuestion: "I want to ask a question",
                bookMeeting: "I want to book a meeting",
                leaveComment: "I want to leave a comment",
                beContacted: "I want to be contacted",
                findOptician: "Find an optician"
            },
            questionFieldsetSelector: ".fieldset-message",
            zipFieldsetSelector: ".fieldset-zip"
        },
        u = $("body"),
        f = $(window),
        c = $(d.headerSelector);
    return {
        init: e
    }
}(jQuery),
    function ($) {
        "use strict";
        $(function () {
            function e() {
                Seiko.portal.init()
            }

            function t() {
                e()
            }
            var i = $("body"),
                n = $("html");
            t()
        })
    }(jQuery);