window.ContactPage = {
    opts: {
        desktopMenuBreakpoint   : 992
    },
    init: function () {
        var self            = this,
            $win            = $(window),
            $navbar         = $('.contact-nav'),
            $navbarToggle   = $('.nav-toggle'),
            updateHeight    = function(){
                if ($navbar.hasClass('expanded') && $win.width() < (self.opts.desktopMenuBreakpoint - 1)) {
                    var height = $navbar.find('.nav-toggle').outerHeight() + $navbar.find('.pages').outerHeight();
                    $navbar.height(height);
                }else if ($win.width() > self.opts.desktopMenuBreakpoint) {
                    $navbar.removeClass('expanded');
                    resetHeight();
                }else {
                    resetHeight();
                }
            },
            resetHeight     = function(){
                $navbar.removeAttr('style');
            };

        $(window).resize(function(){
            updateHeight();
        });

        $navbarToggle.on('click', function () {

            if ($navbar.hasClass('expanded')) {
                $navbar.removeClass('expanded');
                resetHeight();
            }else {
                $navbar.addClass('expanded');
                updateHeight();
            }
        });
    }
};