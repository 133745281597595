window.Main = {

    opts    : {
        heroInDelay : 350
    },

    resizeTimer: 0, // Used for resizing hero

    init: function () {
         
        var self        = this,
            $win        = $(window),
            $html       = $('html'),
            $body       = $('body');

        (!("ontouchstart" in document.documentElement)) ? $html.addClass('no-touch') : $html.addClass('touch');
 
        if (window.Helpers.isMobileSafari()) {
            $html.addClass('mobile-safari');
        }

        // Detect all kinds of stupidity
        if (window.Helpers.isMicrosoftUser()) { 
            $html
                .addClass('ie-' + window.Helpers.getInternetExplorerVersion())
                .addClass('ms-user')
                .removeClass('touch').addClass('no-touch'); // can't predict touch screen on windows machines;

            var ieVersion = parseInt(window.Helpers.getInternetExplorerVersion(), 10);

            if (ieVersion != 0 && ieVersion < 11) {
                $html.addClass('lt-ie11');
            }
        }

        // Set transitions class to enable transitions
        $body.addClass('transitions');

        //self.initHero();
        //self.initStickyFooter();
        //self.initColumns();
        //self.initHubSpotForms();

        // Disable transitions while resizing
        var resizeTimer;
        $win.on('resize', function(){

            //self.updateHeroHeight();

            $body.removeClass('transitions');
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function(){
                $body.addClass('transitions');
            }, 750);
        });
    },

    //initHero: function(){
    //    var self        = this,
    //        $hero       = $('#hero'),
    //        startTime   = Date.now();
    //
    //    if ($hero.length) {
    //        // Set loaded class on hero when image is loaded
    //        $('.hero.image-background').each(function () {
    //            var $parent = $(this);
    //            $(this).find('.inner-img').imagesLoaded(function () {
    //
    //                var curTime = Date.now();
    //                if ((curTime - startTime) > self.opts.heroInDelay) {
    //                    // The hero imaged load time exceeds the set delay => show immediately
    //                    $parent.addClass('loaded');
    //                } else {
    //                    // The hero image loaded mad fast => delay it a bit
    //                    setTimeout(function () {
    //                        $parent.addClass('loaded');
    //                    }, self.opts.heroInDelay - (curTime - startTime));
    //                    //console.log('Delay hero for ' + (self.opts.heroInDelay - (curTime - startTime)) + 'ms');
    //                }
    //            });
    //
    //            $(this).addClass('initiated');
    //        });
    //
    //
    //        $('.anchor-link', $hero).click(function (event) {
    //            var target = $($(this)[0].hash);
    //            if (target.length) {
    //                // Only prevent default if animation is actually gonna happen
    //                event.preventDefault();
    //                $('html, body').animate({
    //                    scrollTop: target.offset().top
    //                }, 650);
    //            }
    //        });
    //
    //        self.updateHeroHeight();
    //    }
    //
    //},

    //initStickyFooter: function(){
    //    var $body = $('body');
    //
    //    if ($('.sticky-footer').length) {
    //        if (window.SiteSelector.exists() && window.SiteSelector.cookieNotSet()) {
    //            // If site selector hasn't been show yet, set up a listener
    //            // that shows notifications AFTER the selector has been closed
    //            $(document).bind('hoya:closedSiteSelector', function(){
    //                if (!$(this).data('sticky-footer-shown')){
    //                    $body.addClass('sticky-footer-enabled');
    //                    $(this).data('sticky-footer-shown', true);
    //                }
    //            });
    //        }else {
    //            // Use has already set up the selector, show notifications normally
    //            $body.addClass('sticky-footer-enabled');
    //        }
    //    }
    //
    //},

    //initColumns: function(){
    //    // Strip away empty columns if there are any at the end of the column blocks
    //    $('.column-block').each(function(){
    //        if ($(this).find('.col:last').hasClass('empty')) {
    //            $(this).find('.col:last').remove();
    //        }
    //    });
    //},

    //updateHeroHeight: function(){
    //    var $hero       = $('#hero'),
    //        oldWinWidth = $('#hero').data('oldWidth') ? $('#hero').data('oldWidth') : 0; // Only resize if win width changed (if on mobile)
    //
    //    if ($hero.length && window.Helpers.mobilecheck() && $(window).width() < 480) {
    //
    //        // Set static height on touch devices to prevent jump when viewport resizes due to mobile browser UI
    //        if ($hero.length && $hero.hasClass('image-background') && $(window).width() != oldWinWidth) {
    //            $hero.removeAttr('style');
    //
    //            clearTimeout(this.resizeTimer);
    //
    //            var heroHeight = $(window).height() - $('.navbar').height();
    //
    //            this.resizeTimer = setTimeout(function () {
    //                $hero
    //                    .css('height', heroHeight + 'px')
    //                    .data('oldWidth', $(window).width());
    //
    //            }, 1000);
    //
    //            $hero.data('oldWidth', $(window).width());
    //        }
    //
    //    }else if ($hero.length && window.Helpers.mobilecheck()) {
    //        $hero.removeAttr('style');
    //    }
    //}
};


/*******************************************
 *
 *  Main initiation calls
 *
 */
$(function () {
    window.Main.init();
    window.SiteSelector.init();
    window.Notifications.init();
    //window.AnchorMenu.init();
});