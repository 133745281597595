window.Menu = {
    /*--------------------------------------------------
        Properties
     --------------------------------------------------*/
    opts: {
        desktopBreakpoint: 768,
        scrolledDownBodyClass: 'scrolled-down',
        expandedBodyClass: 'menu-expanded',
        navbarSelector: '.menu-inner-wrapper',
        navTriggerSelector: '#mobile-menu-btn',
        onScrollDown: function() {
            // Hide Sub menu on scroll down
            window.SubMenu.collapseMenu();
        }
    },
    lastScrollTop: 0,
    menuHeight: 0,

    /*--------------------------------------------------
        Initialization methods
     --------------------------------------------------*/
    init: function() {
        this.bindWinEvents();
        this.bindEvents();

        //    console.log('window.Menu.init()');
    },
    bindWinEvents: function() {
        var self = this,
            $win = $(window),
            $body = $('body');

        $win.scroll(function() {
            self.updateScroll();
        });

        $win.resize(function() {
            // Collapse expanded mobile menu if window is resized to desktop breakpoint
            if (self.inDesktopMode() && self.isExpanded()) {
                window.SubMenu.collapseMenu();
            }
            this.menuHeight = 0;
        });
    },
    bindEvents: function() {

        var self = this,
            $body = $('body'),
            $mobNavTrigger = $(this.opts.navTriggerSelector)

        /***************************************************
         *  Initiate Mobile Nav Trigger Button
         ***************************************************/
        $mobNavTrigger.on('click', function(e) {
            if (self.isExpanded()) {
                self.collapseMobileMenu();
            } else {
                self.expandMobileMenu();
            }
            e.preventDefault();
        });

        $('.logout').click(function(e) {
            e.preventDefault();
            e.stopPropagation();
            var logoutRedirectUrl = $(this).data('logout-url');
            $.post("/api/LoginRegister/Logout/",
                function() {
                    window.location.href = logoutRedirectUrl;
                });
        });

        // Collapse mobile menu on ESC key press
        $(document).keyup(function(e) {
            if (e.keyCode === 27) {
                self.collapseMobileMenu();
            }
        });

        /*******************************************************
         *  Create listeners
         */
        $(document).bind('seiko:openedSiteSelector', function() {
            // When site selector has been opened
            self.collapseMobileMenu();
        });
        $(document).bind('seiko:closedSiteSelector', function() {
            // When site selector has been closed
        });
    },

    /*--------------------------------------------------
        Action methods
     --------------------------------------------------*/
    expandMobileMenu: function() {
        $('body')
            .addClass(this.opts.expandedBodyClass)
        $(document).trigger('seiko:openedNav');
        $(document).trigger('seiko:openedMobileNav');
    },
    collapseMobileMenu: function() {
        $('body')
            .removeClass(this.opts.expandedBodyClass)
        $(document).trigger('seiko:closedMobileNav');
    },

    /**
     * updateScroll()
     * --------------
     * Sets the .scrolled-down (opts.scrolledDownBodyClass) class when scrolling down
     * Called on window.scroll
     */
    updateScroll: function() {
        var $body = $('body'),
            $navbar = $(this.opts.navbarSelector),
            barHeight = $navbar.height(),
            delta = 5,
            scrollY = $(window).scrollTop();

        if (Math.abs(this.lastScrollTop - scrollY) <= delta)
            return;

        if (scrollY > this.lastScrollTop && scrollY > barHeight && !$body.hasClass(this.opts.scrolledDownBodyClass)) {
            // Scroll Down
            $body.addClass(this.opts.scrolledDownBodyClass);

            if (typeof this.opts.onScrollDown === 'function') {
                this.opts.onScrollDown.call()
            }

        } else if (scrollY < this.lastScrollTop && $body.hasClass(this.opts.scrolledDownBodyClass)) {
            // Scroll Up
            if (scrollY + $(window).height() < $(document).height()) {
                $body.removeClass(this.opts.scrolledDownBodyClass);
            }
        }

        this.lastScrollTop = scrollY;
    },

    /*--------------------------------------------------
        State methods
     --------------------------------------------------*/
    isExpanded: function() {
        return $('body').hasClass(this.opts.expandedBodyClass);
    },
    isCollapsed: function() {
        return !$('body').hasClass(this.opts.expandedBodyClass);
    },
    inDesktopMode: function() {
        return $(window).width() >= this.opts.desktopBreakpoint;
    },
    inMobileMode: function() {
        return $(window).width() < this.opts.desktopBreakpoint;
    }
};


/*****************************************

  Sub Menu Object
  
*****************************************/
window.SubMenu = {
    /*--------------------------------------------------
        Properties
     --------------------------------------------------*/
    opts: {
        desktopBreakpoint: 768,
        scrollSpeed: 350,
        expandedBodyClass: 'submenu-expanded',
        activeClass: 'active', // Current page item
        expandedClass: 'expanded', // Expand sub navigation
    },

    /*--------------------------------------------------
        Initialization methods
     --------------------------------------------------*/
    init: function() {
        this.bindMenuEvents();
        this.bindArrowEvents();
        this.bindWinEvents();
        this.initNavArrows();

        //  console.log('window.SubMenu.init()');
    },
    bindWinEvents: function() {
        var self = this,
            $win = $(window),
            $body = $('body');

        $win.resize(function() {
            // Collapse sub menu in mobile breakpoint
            //if (self.inMobileMode() && self.isExpanded()) {
            //    self.collapseMenu();
            //}

            self.initNavArrows();
        });
    },

    bindMenuEvents: function() {

        var self = this,
            $body = $('body')

        // Hack to set .submenu-expanded on body load if any sub menus are expanded
        if ($('.navbar-nav > li.has-child.active.expanded').length) {
            $('body').addClass(this.opts.expandedBodyClass)
        }

        //
        // Bind submenu toggle on parent items
        //
        $('.navbar-nav > li.has-child').each(function() {

            var $parent = $(this);

            if (self.inDesktopMode()) {
                this.menuHeight = $parent.closest('.menu-container').outerHeight();
                $parent.find(".sub-menu").css("top", this.menuHeight);
            }

            // Parent item click event
            $(this).find('> a').on('click', function(e) {

                var isExpanded = $parent.hasClass(self.opts.expandedClass);

                if (isExpanded && self.isExpanded()) {
                    //
                    // Parent Active and Sub Menu is Expanded --> Collapse
                    //
                    self.collapseMenu($parent);
                    this.menuHeight = 0;

                } else if (!isExpanded && !self.isExpanded()) {
                    //
                    // Parent Inactive and Sub Menu is Collapsed --> Expand
                    //
                    self.expandMenu($parent)

                } else if (!isExpanded && self.isExpanded()) {
                    //
                    // Parent is Inactive and Sub Menu is already Expanded --> Expand item (without collapsing)
                    //
                    self.closeAllItems();
                    self.expandMenu($parent);
                }

                e.preventDefault();
            })
        })
    },

    bindArrowEvents: function() {
        var self = this;

        $('.navbar-nav > li.has-child .sub-menu').each(function() {

            var $submenu = $(this),
                $scroller = $submenu.find('.scroller'),
                $leftBtn = $submenu.find('.sub-menu-inner .scroller-button.scroll-left'),
                $rightBtn = $submenu.find('.sub-menu-inner .scroller-button.scroll-right'),
                getSpeed = function(distToScroll, pageWidth) {
                    return distToScroll < pageWidth ? 1.15 * self.opts.scrollSpeed : self.opts.scrollSpeed;
                }

            //
            //  Left scroller button
            //
            $leftBtn.on('click', function() {

                if ($(this).css('pointer-events') === 'auto') {
                    var $submenu = $(this).parent().parent(),
                        $scroller = $submenu.find('.scroller'),
                        pageWidth = $(window).width(),
                        totWidth = self.getScrollableWidth($scroller), //$scroller.find('.items').outerWidth(),
                        scrollWidth = pageWidth - $scroller.find('.item:first').width(), // Page width - one item's width
                        curX = $scroller.scrollLeft(),
                        targetX = Math.ceil(curX - scrollWidth),
                        distToScroll = curX - targetX,
                        leftToScroll = curX;

                    if (distToScroll > leftToScroll) {
                        distToScroll = leftToScroll;
                        targetX = 0;
                    }

                    var scrollSpeed = getSpeed(distToScroll, pageWidth);

                    console.log('distToScroll', distToScroll, 'scrollSpeed', scrollSpeed, 'curX', curX, 'targetX:', targetX, 'leftToScroll', leftToScroll, 'totWidth', totWidth, 'pageWidth', pageWidth);

                    $scroller.animate({
                        scrollLeft: targetX
                    }, scrollSpeed);
                }
            });

            //
            //  Right scroller button
            //
            $rightBtn.on('click', function() {
                if ($(this).css('pointer-events') === 'auto') {
                    var $submenu = $(this).parent().parent(),
                        $scroller = $submenu.find('.scroller'),
                        pageWidth = $(window).width(),
                        totWidth = self.getScrollableWidth($scroller), //$scroller.find('.items').outerWidth(),
                        scrollWidth = pageWidth - $scroller.find('.item:first').width(), // Page width - one item's width
                        curX = $scroller.scrollLeft(),
                        targetX = Math.ceil(curX + scrollWidth),
                        distToScroll = targetX - curX,
                        leftToScroll = Math.round(totWidth - curX - scrollWidth);

                    if (distToScroll > leftToScroll) {
                        distToScroll = leftToScroll;
                        targetX = totWidth - pageWidth;
                    }

                    var scrollSpeed = getSpeed(distToScroll, pageWidth);

                    console.log('distToScroll', distToScroll, 'posAfterScroll:', (curX + targetX), 'scrollSpeed', scrollSpeed, 'curX', curX, 'targetX:', Math.ceil(targetX), 'leftToScroll', leftToScroll, 'totWidth', totWidth, 'pageWidth', pageWidth);

                    $scroller.animate({
                        scrollLeft: targetX
                    }, scrollSpeed);
                }
            });
        });
    },

    /*--------------------------------------------------
        Action methods
     --------------------------------------------------*/
    expandMenu: function($item) {
        //if (this.inDesktopMode()) {
        $('body')
            .addClass(this.opts.expandedBodyClass)
            .removeClass(window.Menu.opts.scrolledDownBodyClass)

        if ($item !== undefined) {
            $item.addClass(this.opts.expandedClass) // it is active
        }
        window.SubMenu.initNavArrows();
        //}
    },
    collapseMenu: function($item) {
        $('body')
            .removeClass(this.opts.expandedBodyClass)
            .removeClass(window.Menu.opts.scrolledDownBodyClass)

        if ($item !== undefined) {
            $item
                .removeClass(this.opts.expandedClass) // it is active
                .find('.sub-menu')
                .removeClass('scrollable-left')
                .removeClass('scrollable-right')
                .find('.scroller').scrollLeft(0);
        } else {
            this.closeAllItems();
        }
    },

    // Collapses all items without removing body class
    closeAllItems: function($item) {
        $('.navbar-nav > li.has-child.' + this.opts.expandedClass)
            .removeClass(this.opts.expandedClass)
            .find('.sub-menu')
            .removeClass('scrollable-left')
            .removeClass('scrollable-right')
            .find('.scroller').scrollLeft(0);
    },

    initNavArrows: function() {
        var self = this,
            $curSubMenu = $('.navbar-nav > li.has-child.expanded .sub-menu');

        if ($curSubMenu.length) {
            var $scroller = $curSubMenu.find('.scroller');

            // Inline function: updateButtons()
            var updateButtons = function() {
                var $scroller = $curSubMenu.find('.scroller'),
                    pageWidth = $(window).width(),
                    totWidth = self.getScrollableWidth($scroller), //$scroller.find('.items').outerWidth(),
                    scrollSize = Math.floor(totWidth - pageWidth),
                    scrollX = $scroller.scrollLeft();

                //console.log('scrollX:', scrollX, 'pageWidth', pageWidth, 'totWidth', totWidth, 'scrollSize', scrollSize);
                //
                // Check if scrollable to the left
                if (scrollX > 0) {
                    $curSubMenu.addClass('scrollable-left');
                } else if (scrollX <= 0) {
                    $curSubMenu.removeClass('scrollable-left');
                }

                // Check if scrollable to the right
                if (scrollX < scrollSize) {
                    $curSubMenu.addClass('scrollable-right');
                } else {
                    $curSubMenu.removeClass('scrollable-right');
                }
            }

            // Bind Scroll Event on Sub Menu scroller
            $scroller
                .off('scroll')
                .on('scroll', function() {
                    updateButtons();
                });

            updateButtons();
        }

        // TODO: Check if has active item, if yes ==> scroll to item
    },

    getScrollableWidth: function($scroller) {
        var totWidth = 0,
            padding = parseInt($scroller.find('.items').css('paddingLeft').replace('px', ''), 10) + parseInt($scroller.find('.items').css('paddingRight').replace('px', ''), 10);
        // Calculate Total Width
        $scroller.find('.items > .item').each(function() {
            totWidth += $(this).width();
        });
        $scroller.find('.items').width(totWidth);
        return totWidth + padding;
    },

    /*--------------------------------------------------
        State methods
     --------------------------------------------------*/
    isExpanded: function() {
        return $('body').hasClass(this.opts.expandedBodyClass);
    },
    isCollapsed: function() {
        return !$('body').hasClass(this.opts.expandedBodyClass);
    },
    inDesktopMode: function() {
        return $(window).width() >= this.opts.desktopBreakpoint;
    },
    inMobileMode: function() {
        return $(window).width() < this.opts.desktopBreakpoint;
    }
};

// On document ready
$(function() {
    window.Menu.init();
    window.SubMenu.init();
});