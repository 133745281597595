window.Notifications = {

    opts: {
        cName_Cookies: 'cookie-consent',
        cName_NA: 'na-notice',
        cDismissParamName: 'c', // Query parameter name to use to dismiss cookie consent notification
        cookieExpire: 7,
        cookieDomain: '',
        stickyFooterDelay: 1500, // Set same value as sticky footer's CSS transition-delay vale
        animInDelay: 750,
        animOutDuration: 250,
        animInterval: 250,
        // Used in non-HubSpot sites to attach cookie parameter
        hsLinkSelectors: 'a[href*="blog.seikovision.com"], a[href*="info.seikovision.com"]',
        // Used in HubSpot to attache cookie parameter
        comLinkSelectors    : 'a[href*="seikovision.com"], a[href*="http://seikovision.azurewebsites.net"], a[href*="http://dev.seikovision.com"]'
    },

    init: function () {

        var self = this,
            $body = $('body'),
            $cookieNotice = $('.notification--primary'),
            $naNotice = $('.notification--secondary'),
            cConsentDismissed = Cookies.get(self.opts.cName_Cookies, { domain: self.opts.cookieDomain }),
            naNoticeDimissed = Cookies.get(self.opts.cName_NA, { domain: self.opts.cookieDomain });


        // Debug: Set ?clearcookies=1 to clear cookies
        if (window.Helpers.getUrlParamByName('clearcookies') == 1) {
            Cookies.remove(self.opts.cName_Cookies);
            Cookies.remove(self.opts.cName_NA);
        }

        // Check if Dismiss query parameter has been set, if yes, save the cookie.
        // This is used to tell HubSpot page to stfu...
        if (window.Helpers.getUrlParamByName(self.opts.cDismissParamName) == 1) {
            self.saveCookieConsentCookie();
        }


        // Show notifications (or not)
        if (!window.Helpers.inHubSpotPage() && window.SiteSelector.exists() && window.SiteSelector.cookieNotSet()) {
            // If site selector hasn't been show yet, set up a listener
            // that shows notifications AFTER the selector has been closed
            $(document).bind('seiko:closedSiteSelector', function () {
                if (!$(this).data('notifications-shown')) {
                    self.showNotifications(); //{ delay: 0 });
                    $(this).data('notifications-shown', true);
                }
            });
        } else {
            // Use has already set up the selector, show notifications normally
            self.showNotifications();
        }


        self.updateNavLinks();
    },

    showNotifications: function (options) {

        options = options || {};

        var self = this,
            $cookieNotice = $('.notification--primary'),
            $naNotice = $('.notification--secondary'),
            initialDelay = (options.delay != undefined) ? options.delay : 0;

        if (options.delay == undefined && $('body').hasClass('sticky-footer-enabled')) {
            initialDelay = self.opts.stickyFooterDelay;
        }


        // Bind Cookie Consent notification
        if ($cookieNotice.length && !self.cookieConsentDismissed()) {

            /*****************************************
             *
             *  Show Cookie Consent Notification
             */

            $cookieNotice.find('.close-link').bind('click', function (e) {
                e.preventDefault();

                self.saveCookieConsentCookie();

                $cookieNotice.removeClass('show');
                $cookieNotice.height($cookieNotice.height()); // set height to enable css dismiss transition

                // Transition out
                setTimeout(function () {
                    $cookieNotice.addClass('dismissed');
                }, self.opts.animOutDuration);
                return false;
            });

            setTimeout(function () {
                $cookieNotice.addClass('show');
            }, initialDelay + self.opts.animInDelay);

        } else if ($cookieNotice.length && self.cookieConsentDismissed()) {

            /*****************************************
             *
             *  Dismissed, remove notification
             *
             */
            $cookieNotice.remove();
            $cookieNotice = false; // set to false to let NA notification appear faster


            self.updateNavLinks(); // Cookie consent has been dismissed, update HubSpot links

        }

        // Bind North America notice notification
        if ($naNotice.length && !self.northAmericaNoticeDismissed()) {

            /*****************************************
             *
             *  Show North America notification
             */

            var delay = ($cookieNotice.length) ? self.opts.animInDelay + self.opts.animInterval : self.opts.animInDelay;

            $naNotice.find('.close-link').bind('click', function (e) {
                e.preventDefault();

                self.saveNorthAmericaNoticeCookie();

                $naNotice.removeClass('show');
                $naNotice.height($naNotice.height()); // set height to enable css dismiss transition

                // Transition out
                setTimeout(function () {
                    $naNotice.addClass('dismissed');
                }, self.opts.animOutDuration);

                return false;
            });

            setTimeout(function () {
                $naNotice.addClass('show');
            }, initialDelay + delay);

        } else if ($naNotice.length && naNoticeDimissed) {

            /*****************************************
             *
             *  Dismissed, remove notification
             *
             */
            $naNotice.remove();

        }
    },

    cookieConsentDismissed: function () {
        return Cookies.get(this.opts.cName_Cookies, { domain: this.opts.cookieDomain });
    },

    northAmericaNoticeDismissed: function () {
        return Cookies.get(this.opts.cName_NA, { domain: this.opts.cookieDomain });
    },

    saveCookieConsentCookie: function () {
        var self = this;
        // Save cookie
        Cookies.set(self.opts.cName_Cookies, 1, {
            expires: self.opts.cookieExpire,
            domain: self.opts.cookieDomain
        });

        self.updateNavLinks(); // Update HubSpot links
    },

    saveNorthAmericaNoticeCookie: function () {
        var self = this;
        // Save cookie
        Cookies.set(self.opts.cName_NA, 1, {
            expires: self.opts.cookieExpire,
            domain: self.opts.cookieDomain
        });
    },

    // Set the cookie dismiss parameter on all nav links
    updateNavLinks: function () {

        if (this.cookieConsentDismissed()) {
            var self = this,
                selectors = !window.Helpers.inHubSpotPage() ? self.opts.hsLinkSelectors : self.opts.comLinkSelectors;

            $(selectors).each(function () {

                if ($(this).attr('href') != '#' && $(this).attr('href') != '') {
                    var url = $(this).attr('href');

                    if (url.indexOf('?') === -1) {
                        url += '?' + self.opts.cDismissParamName + '=1';
                    } else if (url.indexOf('?' + self.opts.cDismissParamName + '=1') === -1 && url.indexOf('&' + self.opts.cDismissParamName + '=1') === -1) {
                        url += '&' + self.opts.cDismissParamName + '=1';
                    }

                    $(this).attr('href', url);
                }
            });
        }
    }
};


//$(function () {
//    window.Notifications.init();
//});