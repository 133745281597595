window.SiteSelector = {

    opts    : {
        cookieName          : 'site-default',
        cookieExpire        : 7,
        cookieDomain        : '',
        cookieGlobalValue   : 'global',
        cDismissParamName   : 's', // Query parameter name to use to dismiss site-selector
        openSelectorClass   : 'site-selector-expanded',
        langExpandClass     : 'lang-expanded',
        initDelay           : 500,
        
        // Used in HubSpot to attache cookie parameter
        comLinkSelectors    : 'a[href*="seikovision.com"], a[href*="http://seikovision.azurewebsites.net"], a[href*="http://dev.seikovision.com"]'
    },

    init: function () {
        var self = this,
            forceOpenSelector = window.Helpers.getUrlParamByName('site-selector') == 1 ? true : false;

        self.siteSelectorElement = $('#site-selector');

        // Debug: Set ?clearcookies=1 to clear cookies
        if (window.Helpers.getUrlParamByName('clearcookies') == 1) {
            Cookies.remove(self.opts.cookieName);
        }

        // Check if Dismiss query parameter has been set, if yes, save the cookie.
        // This is used to tell HubSpot page to stfu...
        if (window.Helpers.getUrlParamByName(self.opts.cDismissParamName) == 1) {
            self.setCookie();
        }

        // Create listeners
        $(document).bind('submenu-expanded', function () {
            self.closeSelector();
        });
        // Create listeners
        $(document).bind('seiko:openedNav', function(){
            self.closeSelector();
        });
        $(document).bind('seiko:openedMobileNav', function () { });
        
        // Bind events
        if (($('#language-selector').length && self.siteSelectorElement.length) || ($('#language-selector-smaller-devices').length && self.siteSelectorElement.length)) {
            // Open site selector
            $('#language-selector').on('click', function (e) {
                //e.preventDefault();

                if ($('body').hasClass(self.opts.openSelectorClass)) {
                    self.closeSelector();
                } else {
                    self.openSelector();
                }

                return false;
            });

            // Open site selector in tablet and mobile (different links)
            $('#language-selector-smaller-devices').on('click', function (e) {
                //e.preventDefault();

                if ($('body').hasClass(self.opts.openSelectorClass)) {
                    self.closeSelector();
                } else {
                    self.openSelector();
                }

                return false;
            });

            // Close site selector
            $('.close-site-selector').on('click', function (e) {
                e.preventDefault();

                self.closeSelector();

                return false;
            });

            // Stop site selector from closing (due to body click event) when we click on the box
            $('#site-selector > div').on('click', function (e) {                
                e.stopPropagation();
            });

            // If user clicked on a link containing .set-global-site, save cookie
            // and redirect or close down selector
            $('.set-global-site').on('click', function (e) {
                if (!window.Helpers.inHubSpotPage()) {
                    //e.preventDefault();

                    self.setCookie();
                    //self.closeSelector();                    
                }
            });

            self.siteSelectorElement.on('click', function (e) {
                if (e.target === this) {
                    self.closeSelector();
                }
            });

            // If user clicked a country link that points to global website (no local version exists)
            $('.region-block__country .country__wrap a').on('click', function (e) {
                var src = $(this).attr('href').slice(0, $(this).attr('href').length - 1)
                var host = window.location.protocol + '//' + window.location.hostname;

                if (!window.Helpers.inHubSpotPage() && src === host) {
                    self.setCookie();
                }
            });
            $('.region-block__country--multi-lang li a').on('click', function (e) {
                var src = $(this).attr('href').slice(0, $(this).attr('href').length - 1)
                var host = window.location.protocol + '//' + window.location.hostname;

                if (!window.Helpers.inHubSpotPage() && src === host) {
                    self.setCookie();
                }
            });

            // Init multi language dropdowns
            $('.region-block__country--multi-lang').each(function () {
                var $parent = $(this);

                $parent.find('> div > a').on('click', function (e) {
                    e.stopPropagation();
                    e.preventDefault();

                    if ($parent.hasClass(self.opts.langExpandClass)) {
                        self.collapseLangNav($parent);
                    } else {
                        self.expandLangNav($parent);
                    }
                    return false;
                });

            });


            // Enable ESC key
            $(document).keyup(function (e) {
                if (e.keyCode === 27) {
                    if ($('body').hasClass(self.opts.openSelectorClass)) {
                        self.closeSelector();
                    }
                }
            });


            //
            // If user hasn't set the global site as default, show the site selector
            // on page initialization. Only show if on global site and not if in HubSpot page
            //
            if (!self.siteSelectorElement.hasClass('hide-on-page-load') && !window.Helpers.inHubSpotPage() && (self.cookieNotSet() || forceOpenSelector)) {
                setTimeout(function () {
                    self.openSelector();
                }, self.opts.initDelay)
            }


            self.updateNavLinks();

            var globalImageContainer = $('.global-image-container .global-image');
            if (globalImageContainer.length) {
                var src = globalImageContainer.data('src'),
                    alt = globalImageContainer.data('alt');

                src = src.indexOf('?') === 0 ? '' : src;

                if (src) {
                    globalImageContainer
                        .html('<div class="img-placeholder" style="background-image: url(' + src + ');"><img src="' + src + '" alt="' + alt + '"/></div>');
                    globalImageContainer.imagesLoaded(function () {
                        $(this).addClass('loaded');
                    });
                } else {
                    // No image src
                    globalImageContainer.html('<div class="img-placeholder"></div>');
                }
            }
        }
    },

    exists: function () {
        return this.siteSelectorElement && this.siteSelectorElement.length;
    },

    cookieNotSet: function () {
        return (!Cookies.get(this.opts.cookieName, { domain: this.opts.cookieDomain }) || Cookies.get(this.opts.cookieName, { domain: this.opts.cookieDomain }) !== this.opts.cookieGlobalValue);
    },

    cookieIsSet: function () {
        return this.cookieNotSet()
    },

    setCookie: function () {
        // Set "site default" cookie to global value
        Cookies.set(this.opts.cookieName, this.opts.cookieGlobalValue, {
            expires: this.opts.cookieExpire,
            domain: this.opts.cookieDomain
        });
    },

    openSelector: function () {
        var self = this;
        if (self.exists()) {
            window.requestAnimationFrame(function () {
                $(document).trigger('seiko:openedSiteSelector'); // trigger event that other objects can listen to
                self.setScrollbarOffsets();
                self.siteSelectorElement.scrollTop(0);
              
                $('body')
                    .addClass(self.opts.openSelectorClass)
                    .on('click', function () {
                        self.closeSelector();
                    });
            });
        }
    },

    closeSelector: function () {
        var self = this;
        self.removeScrollbarOffsets();

        window.requestAnimationFrame(function () {
            $(document).trigger('seiko:closedSiteSelector'); // trigger event that other objects can listen to
            $('body')
                .removeClass(self.opts.openSelectorClass)
                .off('click');
        });

        self.setCookie();
    },

    setScrollbarOffsets: function () {
        var scrollbarWidth = window.Helpers.getScrollBarWidth();

        var containers = $('.navbar').find('> div');
        $('.navbar').css('padding-right', scrollbarWidth);
        //containers.each(function (node, index) {
        //    var $this = $(this);
        //    var oldPadding = parseInt($this.css('padding-right'));
        //    $this.css('padding-right', oldPadding + scrollbarWidth);
        //});
        // Add scrollbar width to navbar to prevent jump

        $('.navbar-heading > span').css('padding-right', scrollbarWidth);
        $('#main').css('padding-right', scrollbarWidth);
        $('.sticky-footer__inner').css('padding-right', scrollbarWidth);

        //$('.site-selector-overlay__inner').css('padding-left', scrollbarWidth);
        $('.site-selector-overlay__close').css('right', scrollbarWidth);

        //console.log('Site selector: set sb offsets');
    },

    removeScrollbarOffsets: function () {

        if (!$('body').hasClass('menu-expanded')) {
            // Reset scrollbar width compensation in navbar
            $('.navbar').removeAttr('style'); // Remove style attributes
            $('.navbar-heading > span').removeAttr('style');
            $('#main').removeAttr('style');
            $('.sticky-footer__inner').removeAttr('style');
            //console.log('Site selector: reset sb offsets');
        }

        //$('.sticky-footer__inner').removeAttr('style');
        $('.site-selector-overlay__close').removeAttr('style');
    },


    expandLangNav: function ($el) {
        var self = this,
            $wrap = $el.find('> div');

        this.collapseAllLangNav(); // collapse all other

        $wrap.removeAttr('style');

        var height = $el.outerHeight() + $($wrap.find('ul')).outerHeight() + 10;

        $wrap.height(height);

        $el.addClass(self.opts.langExpandClass);

        $('body').on('click', function () {
            self.collapseLangNav($el);
        });
    },

    collapseLangNav: function ($el) {
        var $wrap = $el.find('> div');
        $wrap.removeAttr('style');
        $el.removeClass(this.opts.langExpandClass);

        $('body').off('click');
    },

    collapseAllLangNav: function () {
        var self = this;

        $('.region-block__country--multi-lang.' + this.opts.langExpandClass).each(function () {
            self.collapseLangNav($(this));
        })
    },

    // Set the site selector dismiss parameter on all nav links if in HubSpot page
    updateNavLinks: function () {

        if (window.Helpers.inHubSpotPage()) {
            var self = this,
                selectors = self.opts.comLinkSelectors;

            $(selectors).each(function () {

                if ($(this).attr('href') != '#' && $(this).attr('href') != '') {
                    var url = $(this).attr('href');

                    if (url.indexOf('?') === -1) {
                        url += '?' + self.opts.cDismissParamName + '=1';
                    } else if (url.indexOf('?' + self.opts.cDismissParamName + '=1') === -1 && url.indexOf('&' + self.opts.cDismissParamName + '=1') === -1) {
                        url += '&' + self.opts.cDismissParamName + '=1';
                    }

                    $(this).attr('href', url);
                }
            });
        }
    }
};