export class SeikoLoginRegisterPage {
    private $pageContainer = $('.login-register-page');

    public init() {
        if (!this.$pageContainer.length) {
            return;
        }
        
        this.initLogin();
    }

    private initLogin() {
       let form = $('.login-block #login-form');
       let loginAction = form.data('login-action');
       let forgotPasswordAction = form.data('forgot-password-action');

       $('#forgot-password-link').click(e => {
           e.preventDefault();
           $('.login-block').addClass("forgot-password");
           form.attr('action', forgotPasswordAction);
       });
       $('#back-to-login-link').click(e => {
           e.preventDefault();
           $('.login-block').removeClass("forgot-password");
           form.attr('action', loginAction);
       });
    }

    public loginComplete(result: any) {
       if (result.Success) {
           window.location.href = result.ReturnUrl;
       }
       else {
           $('#login-form .validationText').html(result.ResponseMessage);
       }
    }
    public requestResetComplete(result: any) {
       if (result.Success) {
           $('#request-reset-form').addClass('success');
           $('#request-reset-form .message').html(result.ResponseMessage);
           $('#request-reset-form .validationText').html('');
       }
       else {
           $('#request-reset-form .message').html('');
           $('#request-reset-form .validationText').html(result.ResponseMessage);
       }
    }
    public resetPasswordComplete(result: any) {
       if (result.Success) {
           $('#new-password-form').addClass('success');
           $('#new-password-form .message').html(result.ResponseMessage);
           $('#new-password-form .validationText').html('');
       }
       else {
           $('#new-password-form .validationText').html(result.ResponseMessage);
           $('#new-password-form .message').html('');
       }
    }
    public registerComplete(result: any) {
       if (result.Success) {
           $('#register-form .successMessage').html(result.ResponseMessage);
       }
       else {
           $('#register-form .validationText').html(result.ResponseMessage);
       }
    }
}
